
import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { ConfigService } from "app/config.service";
import { environment } from "environments/environment";

@Component({
  selector: "vp-version-dialog",
  templateUrl: "./version-dialog.component.html"
})
export class VersionDialogComponent {
  app_version = "1.0.1.72";
  appLogo: string;
  aboutText: string;
  changeLog: string;
  backgroundImage = "url('assets/background-mcb.png')";
  prefixBold: string;
  suffixNormal: string;
  constructor(
    public dialogRef: MatDialogRef<VersionDialogComponent>,
    private configService: ConfigService
  ) {
    this.prefixBold = "VNC";
    this.suffixNormal = "mcb";
    this.appLogo = environment.aboutUsLogo;
    this.aboutText = environment.aboutText;
    if (this.configService.URLS.changeLog) {
      this.changeLog = this.configService.URLS.changeLog;
    } else {
      this.changeLog = "https://redmine.vnc.biz/projects/changelog/wiki/VNCmcb";
    }
  }

  close() {
    this.dialogRef.close();
  }
}
